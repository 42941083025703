.wrapper {
    width: calc(100vw - 170px);
    height: calc(100vh - 160px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: #13162A;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    color: #888EA8;
}

.profile h1 {
    text-align: center;
    color: inherit;
    margin: 20px 0;
    font-size: 1.4rem;
}