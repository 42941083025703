.search {
    font-size: 1.2rem;
}

.search input {
    font-size: 1.2rem;
    height: 1.5rem;
    padding: 5px;
}

.search label {
    padding-left: 10px;
    line-height: 1.2rem;
}