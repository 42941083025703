.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    display: flex;
    flex: 1 1 400px;

}

.content main {
    color: #E1E6ED;
}


@media all and (max-width: 700px) {}