.item {
    /* padding-top: 10px; */
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items: center; */
    /* width: 100%; */
    height: 100%;
    /* margin: 1rem; */
}

.item h1 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: inherit;
    font-weight: normal;
}

.title {
    /* border-bottom: 1px dashed #3b3f5c; */
    padding: 5px;
    margin-bottom: 5px;

}

.content {
    padding: 5px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content p {
    color: inherit;
    line-height: 1.6rem;
    font-size: 1.2rem;
}