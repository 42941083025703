.control {
    margin-bottom: 0.5rem;
}

.control label {
    display: block;
    color: inherit;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.control input {
    font: inherit;
    background-color: #f1e1fc;
    color: #38015c;
    border-radius: 4px;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
}

.action {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.action button {
    cursor: pointer;
    font: inherit;
    color: #E1E6ED;
    background: linear-gradient(229deg, #517281 0%, #3b5d70 27%, #4d5c82 72%, #5d647f 100%);
    /* border: 1px solid #9f5ccc; */
    border-radius: 6px;
    border: none;
    padding: 0.5rem 2.5rem;
}

.action button:hover {
    background-color: #873abb;
    border-color: #873abb;
    color: white;
}

.msg {
    margin: 5px 0 15px 0;
}

.error {
    margin: 5px 0 15px 0;
    color: red;
}

.success {
    margin: 5px 0 15px 0;
    color: green;
}