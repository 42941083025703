.wrapper {
    padding-left: 20px;
}

.title {
    font-size: 2rem;
    padding: 5px 0 20px 0;
    text-transform: uppercase;
}

.group h1 {
    font-size: 1.3rem;
    padding: 20px 0 0 0;
}