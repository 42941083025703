.wrapper {
    width: 100vw;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: #13162A;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    color: #888EA8;
}

.auth h1 {
    text-align: center;
    color: inherit;
    margin: 20px 0;
    font-size: 1.4rem;
}

.control {
    margin-bottom: 0.5rem;
}

.control label {
    display: block;
    color: inherit;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.control input {
    font: inherit;
    background-color: #f1e1fc;
    color: #38015c;
    border-radius: 4px;
    border: 1px solid white;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
}

.actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions button {
    cursor: pointer;
    font: inherit;
    color: #E1E6ED;
    background: linear-gradient(229deg, #517281 0%, #3b5d70 27%, #4d5c82 72%, #5d647f 100%);
    /* border: 1px solid #9f5ccc; */
    border-radius: 6px;
    border: none;
    padding: 0.5rem 2.5rem;
}

.actions button:hover {
    background-color: #873abb;
    border-color: #873abb;
    color: white;
}

.actions .toggle {
    margin-top: 1rem;
    background: transparent;
    color: #9f5ccc;
    border: none;
    padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
    background: transparent;
    color: #ae82cc;
}