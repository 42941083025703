.item {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 100%;
    /* margin: 1rem; */
}

.item h1 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #ee558f;
    font-weight:normal;
}

.domainName {
    border-bottom: 1px dashed #3b3f5c;
    padding: 5px;
    margin-bottom: 5px;

}

.content {
    padding: 5px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item p {
    color: inherit;
    line-height: 1.6rem;
    font-size: 0.9rem;
}

.item p span {
    font-size: 1rem;
    padding-left: 5px;
}
.item p.expiring {
    color: red;
}