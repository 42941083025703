.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 10px;
    /* height: 70px; */
    flex: 0 0 50px;
    background-color: #13162A;
    border-radius: 6px;
    margin: 10px;
    color: #83A4CB;
}

.header>nav>ul {
    display: flex;
}

.header>nav>ul>li {
    padding: 10px 15px;
    cursor: pointer;
}

.sup {
    margin-left: 0.3rem;
    font-size: 0.6rem;  
    vertical-align: super;  
}