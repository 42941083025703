.wrapper {
    padding-left: 20px;
}

.title {
    font-size: 2rem;
    padding: 5px 0 20px 0;
    text-transform: uppercase;
}

.item {
    /* padding-top: 10px; */
    display: flex;
    /* flex-direction: column; */
    /* width: 100%; */
    height: 100%;
    /* margin: 1rem; */
    justify-content: center;
    align-items: center;
}

.add {
    width: 4rem;
    height: 4rem;
    font-size: 4rem;
    display: block;

}