.sidebar {
    width: 150px;
    margin: 0 10px;
    border-radius: 6px;
    padding-left: 15px;
    background-color: #13162A;
    color: #888EA8;
    font-size: 1.2rem;
    flex: 0 0 150px;
}

.sidebar .active {
    color: #61B6CD;
    background-color: #202347;
}

.sidebar a {
    width: 135px;
}
.logo {
    height: 16px;
    width: 16px;
    display: inline;
    filter: invert(62%);
    margin-right: 5px;
}

.sidebar li {
    margin: 15px 0;
}