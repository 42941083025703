.details {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin: 25px 25%;
}

.details table {
    color: #888EA8;
}

.value, .valueTextArea {
    color: #BFC9D4;
    padding-left: 10px;
    padding-bottom: 5px;
}

.valueTextArea {
    padding-left: 0;
    padding-bottom: 20px;
}

.details th,
.blockName {
    text-align: center;
}

.details th {
    padding-bottom: 20px;
    color: white;
    font-size: 1.5rem;
}

.blockName {
    padding: 10px 0;
    text-decoration: underline;

}

.title,
.blockName {
    font-weight: bold;
}

/* .details textarea {
    background-color: transparent;
    border: 1px solid #BFC9D4;
    color: #BFC9D4;
    font-size: 1rem;
    /* font-weight: bold; 
    font-family: inherit;
    width: 100%;
    height: 5rem;
    resize: none;
}

.details textarea:read-only {
    border: none;
} */

.details input {
    background-color: transparent;
    color: #BFC9D4;
    font-size: 1rem;
    font-family: inherit;
    padding-left: 5px;
    border: 1px solid #BFC9D4;
}

.details input:read-only {
    border: none;
}

.details input:read-write {}

.details button {
    cursor: pointer;
    font: inherit;
    color: #E1E6ED;
    background: linear-gradient(229deg, #517281 0%, #3b5d70 27%, #4d5c82 72%, #5d647f 100%);
    /* border: 1px solid #9f5ccc; */
    border-radius: 6px;
    border: none;
    padding: 0.5rem 2.5rem;
    width: 50%;
    margin-left: 25%;
    margin-right: auto;
}

.details a {
    pointer-events: cursor;
    color: #BFC9D4;
    padding-left: 5px;
    /* padding-bottom: 5px; */
}

.details button[type='submit'] {
    margin-top: 20px;
}
/* 
.disabled {
    pointer-events: none;
} */

.actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
}

.actions button {
    margin: 0;
    width: 35%;
    padding: 0.5rem 1rem;
}

.high {
    height: 29px;
    font-size: 1rem;
}

/* fix for autofill styling in chrome engine */
.details input:-webkit-autofill,
.details input:-webkit-autofill:hover,
.details input:-webkit-autofill:focus,
.details textarea:-webkit-autofill,
.details textarea:-webkit-autofill:hover,
.details textarea:-webkit-autofill:focus,
.details select:-webkit-autofill,
.details select:-webkit-autofill:hover,
.details select:-webkit-autofill:focus {
    border: 1px solid #BFC9D4;
    -webkit-text-fill-color: #BFC9D4;
    -webkit-box-shadow: 0 0 0px 1000px #1E2E4B inset;
}