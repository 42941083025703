.gridItem {
    padding: 0;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    margin: 15px;
    width: 300px;
    text-align: center;
    background-color: #101726;
    color: inherit;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-content: center; */
}

.gridContent {
    flex: 1 1 120px;
    /* padding: 10px; */
    border-radius: 6px;
}

.footer {
    font-size: 0.9rem;
    width: 300px;
    background: linear-gradient(229deg, #517281 0%, #3b5d70 27%, #4d5c82 72%, #5d647f 100%);
    color: inherit;
    flex: 0 0 30px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}