.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 30px;
    background-color: #13162A;
    margin: 10px;
    border-radius: 6px;
    color: #888EA8;
}

.footer>a {
    text-decoration: none;
    color: inherit;
    padding-left: 8px;
}